<template>
  <div class="informationbox pb-30">
    <div class="identitys">
      <div class="identityItem dp-f pt-26 pl-19 cu-p"
        :class="state.choiceIdentity == el.mode? 'identityItemac' : ''" v-for="(el, ind) in state.identitys" :key="ind" @click="selectCitdMode(el.mode)">
        <div class="icon mr-10"></div>
        <div>
          <div class="title mb-8">{{ el.title }}</div>
          <div class="label">{{ el.label }}</div>
        </div>
      </div>
    </div>
    <formList ref="formListRef" :labelWidth="280" class="mb-50" :FormDatas="state.FormData"
      @authenticationTypeEmit="typeDataChang" @submit="submitEmit"></formList>
    <oabutton v-model:loading="state.loading" class="m-0-a mb-30 mt-20" width='462' height='48' title="下一步" CSStype=2
      style="border-radius: 4px;font-size: 18px;" @buttonclick="buttonclick" distype="2"></oabutton>
    <face ref="faceRef" @setface="setface"></face>
  </div>
</template>
<script setup>
import { router_back } from "@/utils/server/router";
import { reactive, defineEmits, ref, unref, computed } from "vue";
import { getAuthenticationTypeData } from "@/utils/server/selectdata.js"
import { validPhone, validIdcard, validChinaName } from "@/utils/server/validate.js";
import { handleMessage } from "@/utils/server/confirm.js"
import axios from 'axios';
import { httpToken } from "@/utils/request";
import qs from "qs";
import formList from '../formList.vue'//form 表单组件
import face from "../face.vue"//人脸识别
import { resetUserInfo } from "@/api/user"
const formListRef = ref(null);//表单ref
const faceRef = ref();//人脸识别弹出框ref
const user = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    if (validPhone(value)) {
      callback()
    } else
      callback(new Error('手机号格式错误'))
  }
}
// 校验身份证格式
const validIdcardData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入身份证'))
  } else {
    if (validIdcard(value)) {
      callback()
    } else
      callback(new Error('身份证格式错误'))
  }
}
// 校验中文姓名
const validChinaNameData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入真实姓名'))
  } else {
    if (validChinaName(value)) {
      callback()
    } else
      callback(new Error('真实姓名格式错误'))
  }
}
// 校验银行卡号
const validBankAccountData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入银行卡号'))
  } else {
    console.log()
    axios({
      url: 'https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=' + value.replace(/ /g, ''),
    }).then(res => {
      // console.log('触发银行卡校验',res.data.validated)
      if (res.data.validated) {
        callback()
      } else
        callback(new Error('银行卡号格式错误'))
    }).catch(err => {
      callback(new Error('银行卡号格式错误'))
    })
  }
}
const state = reactive({
  FormData: [],
  identitys: [
    { title: '手机号', label: '需验证本人手机号信息和证件信息的真实性', mode: 2 },
    { title: '银行卡', label: '需验证本人银行卡信息和证件信息的真实性', mode: 3 },
  ],
  FormBaseData: [
    // { label: '认证方式：', type: 2, key: 'authenticationType', default: '2', rules: [{ required: true, message: '请选择认证方式' }], options: getAuthenticationTypeData() },
    { label: '真实姓名：', type: 1, key: 'name', default: user.value.chnname,rules: [{ validator: validChinaNameData, trigger: 'blur' }] },
    { label: '证件号：', type: 1, key: 'idNumber', default: '', rules: [{ validator: validIdcardData, trigger: 'blur' }] },
  ],
  FormChangeData: [
    { label: '手机号：', type: 1, key: 'phone', default: user.value.phone, disabled: true, rules: [{ validator: validPhoneData, trigger: 'blur' }] },
    { label: '银行卡号：', type: 1, key: 'bankAccount', default: '', rules: [{ validator: validBankAccountData, trigger: 'blur' }] },
    { label: '预留手机号：', type: 1, key: 'phone', default: user.value.phone, rules: [{ validator: validPhoneData, trigger: 'blur' }] },
    { label: '验证码：', type: 1, key: 'token', default: '', rules: [{ required: true, message: '请输入验证码' }] }
  ],
  loading: false,//loading显示
  choiceIdentity: 2,
});
const selectCitdMode = ((mode) => {
  console.log("select ctid mode: ", mode)
  state.choiceIdentity = mode 
  typeDataChang(mode)
}) 
// 按钮点击 触发表单校验
const buttonclick = (() => {
  console.log("start")
  unref(formListRef).submitForm()
})
// 表单校验返回结果
const submitEmit = ((el) => {
  console.log('表单校验返回结果', el, unref(formListRef).formModel)
  if (el) {
    if (state.choiceIdentity == 1) {//人脸识别方式
      unref(faceRef).show(unref(formListRef).formModel)
    } else {
      state.loading = true
      let json = {
        ctidmode: state.choiceIdentity,//认证类型 1.两项实人，姓名 + 身份证号 + 人脸照片2.手机号三要素，姓名 + 身份证号 + 手机号3.银行卡三要素，姓名 + 身份证号 + 银行卡号
        name: unref(formListRef).formModel.name,//真实姓名
        idNumber: unref(formListRef).formModel.idNumber,//证件号 身份证
        phone: unref(formListRef).formModel.phone,//手机号
        // bankAccount: unref(formList).formModel.bankAccount, //银行卡
        code: unref(formListRef).formModel.token,//验证码
      }
      if (state.choiceIdentity == 3) {//银行卡认证情况
        json.bankAccount = unref(formListRef).formModel.bankAccount.replace(/ /g, '')//证件号 银行卡
      }
      httpToken({
        method: "post",
        url: '/admin/certification/personal',
        data: json
      }).then((res) => {
        // console.log('实名认证成功',res)
        state.loading = false
        if (res.code == 200) {
          resetUserInfo(() => {
            emit('setStep', 2)
          })
        }
      }).catch(err => {
        state.loading = false
      })
    }
  }
})
const setface = () => {
  resetUserInfo(() => {
    emit('setStep', 2)
  })
}
// 认证方式改变
const typeDataChang = (el => {
  // console.log("user: ", user.value.chnname, user.phone)
  // state.FormBaseData[0].default = user.chnname
  state.FormData = [...state.FormBaseData]
  if (el == 2) {
    // state.FormChangeData[0].default = user.phone
    state.FormData.push(state.FormChangeData[0])
    state.FormData.push(state.FormChangeData[3])
  } else if (el == 3) {
    // state.FormChangeData[2].default = user.phone
    state.FormData.push(state.FormChangeData[1])
    state.FormData.push(state.FormChangeData[2])
    state.FormData.push(state.FormChangeData[3])
  }
})
typeDataChang(state.choiceIdentity)
const emit = defineEmits(['setStep'])
</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";
.informationbox {
  width: 1120px;
  margin: 0 auto;

  .identitys {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .identityItem {
      width: 300px;
      height: 90px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(154, 154, 154, 0.16);
      border-radius: 8px;

      .icon {
        width: 14px;
        height: 14px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 50%;
      }

      .title {
        line-height: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }

      .label {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }
    }

    .identityItemac {
      box-shadow: none;
      border: 1px solid $active-theme;

      .icon {
        background: #fff;
        border: 4px solid $active-theme;
        box-shadow: 0px 0px 1px 0px $active-theme;
      }
    }

    .identityItem+.identityItem {
      margin-left: 20px;
    }
  }

  .labelbox {
    display: flex;

    .line {
      width: 4px;
      height: 20px;
      background: $active-theme;
      margin-right: 10px;
    }

    font-size: 16px;
    font-family: PingFangSC-Semibold,
    PingFang SC;
    font-weight: 600;
  }
}

::v-deep .el-form-item__content {
  width: 660px !important;
}

</style>